import { Banner } from './ad-type-renderers/banner';
import {
  IApiSliderZone,
  IApiTextLinkZone,
  IBannerApiZone,
  IInterstitialApiZone,
  IPopunderApiZone,
} from './ad-type-renderers/i-zone';
import { TextLink } from './ad-type-renderers/text-link';
import { IApiZone } from './api/api';
import { RemoteLogger } from './sentry';
import { logger } from './sentry/console';
import { AdType, IInserter } from './types';

const dummyInserter = {
  insert() {
    logger.warn('dummy inserter used');
  },
};

export const adInserterFactory = async (
  options: IApiZone
): Promise<IInserter> => {
  if (options.type === AdType.Html) {
    return new Banner(options as IBannerApiZone);
  }

  if (options.type === AdType.Popunder) {
    const { Popunder } = await import('./ad-type-renderers/popunder');
    if (!Popunder) return dummyInserter;
    Popunder.setOptions(options as IPopunderApiZone);
    return Popunder.getInstance();
  }

  if (options.type === AdType.Interstitial) {
    if (!window.canRunAds || !window.canRunAdvertise) {
      return dummyInserter;
    }

    const { Interstitial } = await import('./ad-type-renderers/interstitial');
    if (!Interstitial) return dummyInserter;
    Interstitial.setOptions(options as IInterstitialApiZone);
    return Interstitial.getInstance(options.id);
  }

  if (options.type === AdType.Native) {
    const { Native } = await import('./ad-type-renderers/native');
    if (!Native) return dummyInserter;
    return new Native(options);
  }

  if (options.type === AdType.VideoSlider) {
    const { VideoSlider } = await import('./ad-type-renderers/video-slider');
    if (!VideoSlider) return dummyInserter;

    return new VideoSlider(options as IApiSliderZone);
  }

  if (options.type === AdType.VideoOutstream) {
    const { VideoOutstream } = await import(
      './ad-type-renderers/video-outstream'
    );
    if (!VideoOutstream) return dummyInserter;

    return new VideoOutstream(options as IApiSliderZone);
  }

  if (options.type === AdType.TextLink) {
    return new TextLink(options as IApiTextLinkZone);
  }

  return {
    insert() {
      RemoteLogger.getInstance().captureEvent(
        new Error(`unsupported zone type: ${options.type}`)
      );
    },
  };
};
