import './utils/custom-event';

interface BrowserVersion {
  name: string | RegExp;
  version: string;
  versionNumber: number;
}

export class DOM {
  public isIE: BrowserVersion;

  constructor() {
    this.isIE = DOM.detectIE(navigator.userAgent);
  }

  /**
   * @param html string
   * @param tag string
   *
   * create DOM element
   *
   * @returns HTMLElement
   */

  public createElement(html: string, tag = 'DIV'): HTMLElement {
    const newItem = document.createElement(tag);
    newItem.innerHTML = html;

    Array.from(newItem.querySelectorAll('script')).forEach((oldScript) => {
      const newScript = document.createElement('script');
      Array.from(oldScript.attributes).forEach((attr) => {
        newScript.setAttribute(attr.name, attr.value);
      });
      newScript.appendChild(document.createTextNode(oldScript.innerHTML));
      oldScript.parentNode.replaceChild(newScript, oldScript);
    });

    return newItem;
  }

  /**
   * @param elem HTMLElement
   *
   * @returns void
   *
   * Deletes passed node
   */

  public removeChild(elem: HTMLElement) {
    if (elem && elem instanceof HTMLElement) {
      const parent = elem.parentElement;

      parent && parent.removeChild(elem);
    }
  }

  /**
   * @param newChild HTMLElement
   * @param oldChild HTMLElement
   *
   * @returns void
   *
   * Replaces passed node
   */

  public replaceChild(newChild: HTMLElement, oldChild: HTMLElement) {
    if (
      newChild &&
      newChild instanceof HTMLElement &&
      oldChild &&
      oldChild instanceof HTMLElement
    ) {
      const parent = oldChild.parentElement;

      parent && parent.replaceChild(newChild, oldChild);
    }
  }

  /**
   *
   */

  public isVisible(elem): boolean {
    if (!elem) return false;
    return !!(
      elem.offsetWidth ||
      elem.offsetHeight ||
      elem.getClientRects().length
    );
  }

  /**
   *
   * Detect IE version
   * @param userAgent
   *
   * @returns BrowserVersion
   */
  private static detectIE(userAgent): BrowserVersion {
    const browserDefinitions: [string, RegExp][] = [
      ['ie', /Trident\/7\.0.*rv:([0-9.]+)\).*Gecko$/],
      ['ie', /MSIE\s([0-9.]+);.*Trident\/[4-7].0/],
      ['ie', /MSIE\s(7\.0)/],
    ];
    for (const i in browserDefinitions) {
      const definition = browserDefinitions[i];
      if (definition[1].test(userAgent)) {
        const match = definition[1].exec(userAgent);
        const version = match && match[1].split(/[._]/).slice(0, 3);
        const versionTails =
          Array.prototype.slice.call(version, 1).join('') || '0';
        if (version && version.length < 3) {
          Array.prototype.push.apply(
            version,
            version.length === 1 ? [0, 0] : [0]
          );
        }
        return {
          name: definition[0],
          version: version.join('.'),
          versionNumber: parseFloat(version[0] + '.' + versionTails),
        };
      }
    }
    return {
      name: 'other',
      version: '1.0',
      versionNumber: 1.0,
    };
  }

  /**
   * Check UC browser
   *
   * @returns boolean
   */
  public checkUCB() {
    return /UCBrowser/.test(navigator.userAgent);
  }
}

export class AdvertisingDOM extends DOM {
  public spots: Array<HTMLElement> | undefined;
  private _spotIds: number[] | undefined;
  private _creativeIds: string[] | undefined;
  renderEvent = new CustomEvent('hp-rendered');

  constructor() {
    super();
    this.updateSpots();
  }

  /**
   * updateSpots(): void
   * select all hprofits anchors and cache it
   */
  public updateSpots() {
    const spots = document.querySelectorAll(
      '[data-hp-zone]'
    ) as NodeListOf<HTMLElement>;

    this._spotIds = [];
    this._creativeIds = [];

    if (!spots || spots.length === 0) return;

    this.spots = [...spots];

    this.spots.forEach((el) => {
      this._spotIds.push(Number(el.dataset.hpId));
      const creative = el.dataset.ctId;
      if (creative) {
        this._creativeIds.push(`${el.dataset.hpId}-${creative}`);
      }
    });
  }

  /**
   * get spots anchored on the page
   */
  public get spotIds(): number[] | undefined {
    return this._spotIds;
  }

  /**
   *
   * set creatives
   */

  public setCreatives(creatives: string[]) {
    this._creativeIds = [...creatives];
  }

  /**
   * get creatives in pair with zoneId anchored on the page;
   *
   * @returns string[]. Example: [100-123]. Where 100 is zone id, 123 is creative id.
   */
  public get creativeIds(): string[] | undefined {
    return this._creativeIds;
  }

  /**
   * get spots anchored on the page
   */
  public spotElementById(id: string, remove = false): HTMLElement | undefined {
    return this.spots.find((el, index) => {
      const ok = el.dataset.hpId === id;
      if (remove && ok) {
        this.spots.splice(index, 1);
      }
      return ok;
    });
  }

  public creativeIdByZoneId(id: string): string | null {
    const result = this.creativeIds
      .map((el) => {
        return el.split('-');
      })
      .find((el) => {
        return el[0] === id;
      });
    if (result?.length > 0) {
      return result[1];
    }

    return null;
  }

  /**
   *
   * To avoid double-render we cleans attrs from spot block
   *
   * @param spot HTMLElement
   *
   * @returns void
   */

  public cleanupSpotAttrs(spot: HTMLElement) {
    spot.removeAttribute('data-hp-zone');
    spot.removeAttribute('data-ct-id');
    spot.removeAttribute('data-hp-id');
  }

  /**
   *
   * Check if spots absent
   *
   * @returns boolean
   */
  public get spotsAbsent(): boolean {
    return this.spotIds === undefined || this.spotIds.length === 0;
  }
}

export class AdvertisingDOMSingleton {
  private static instance: AdvertisingDOM | null = null;

  public static getInstance(): AdvertisingDOM {
    if (AdvertisingDOMSingleton.instance === null) {
      AdvertisingDOMSingleton.instance = new AdvertisingDOM();
    }

    return AdvertisingDOMSingleton.instance;
  }
}
